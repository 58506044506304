<style scoped>
.ck {
  cursor: pointer;
}
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">用户列表</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="昵称:">
          <el-input v-model="p.nickName" placeholder="模糊查询" clearable></el-input>
        </el-form-item>
        <br />
        <el-form-item label="商户号:">
          <el-input v-model="p.mchId" placeholder="精确查找" clearable></el-input>
        </el-form-item>
        <br />
        <el-form-item label="账号类型:">
          <el-select :popper-append-to-body="true" v-model="p.roleId" @change="f5">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="商户" :value="1"></el-option>
            <el-option label="码队" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item style="margin-left: 100px">
          <el-button type="primary" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
          <!-- <el-button type="primary" icon="el-icon-paperclip" @click="showUserInfoPage()">切换码队</el-button> -->
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :key="conditionsKey" :row-class-name="tableRowClassName" @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column type="selection" width="30" :selectable="checkSelectable">
        </el-table-column>
        <el-table-column label="商户号" prop="mchId" width="150px" />
        <el-table-column label="昵称" prop="nickName" width="100px" />
        <el-table-column label="用户名" prop="userName" width="100px" />
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.state" :active-value="1" :inactive-value="0" inactive-color="#ff4949" @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.state == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.state == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="角色" width="70px">
          <template slot-scope="s">
            <el-tag type="success" size="mini" v-if="s.row.roleId == '1'">商户</el-tag>
            <el-tag type="danger" size="mini" v-if="s.row.roleId == '3'">码队</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" min-width="150px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.zrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" min-width="150px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks" :formatter="remarksFormat" min-width="150px">
        </el-table-column>
        <!-- <el-table-column label="所属码队" prop="codeMchName" min-width="350px">
        </el-table-column> -->
        <el-table-column prop="address" label="操作" min-width="180px" fixed="right">
          <template slot-scope="s">
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-link" @click.native="payTypeTJ(s.row)">金额统计</el-dropdown-item>
                <!-- <el-dropdown-item icon="el-icon-link" @click.native="liuPayTypeTJ(s.row)">流水统计</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="newPayTypeTJ(s.row)">通道统计</el-dropdown-item> -->
                <el-dropdown-item icon="el-icon-link" @click.native="modifyUserName(s.row)">修改用户名</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="modifyPassword(s.row)">修改密码</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="modifyRemarks(s.row)">修改备注</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="del(s.row)">删除商户</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="userDialogVisible" v-if="userDialogVisible" width="330px" append-to-body>
      <!--  传递给子组件的值     -->
      <SwitchCode @mchId="getSwitchMchId"></SwitchCode>
      <div slot="footer" class="dialog-footer">
        <el-button @click="switchMch()">切 换</el-button>
        <el-button @click="cancel()">取 消</el-button>
      </div>
    </el-dialog>
    <EditUserPassword ref="edit-user-password"></EditUserPassword>
  </div>
</template>

<script>
import EditUserPassword from "../user/edit-user-password.vue";
import SwitchCode from "./switch-code-mch.vue";
export default {
  components: { SwitchCode, EditUserPassword },
  data() {
    return {
      p: {
        // 查询参数
        mchId: "",
        nickName: "",
        roleId: "",
        page: 1,
        limit: 10,
      },
      title: "切换码队",
      userDialogVisible: false,
      dataCount: 1422,
      dataList: [],
      multipleSelection: null,
      switchMchId: null,
      codeMchName: null,
      conditionsKey: 0,
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      this.sa.ajax(
        "/v2/admin/getMerchants",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    modifyUserName: function (data) {
      let self = this;
      this.sa.prompt(
        "输入要修改的用户名",
        function (value) {
          var p = {
            mchId: data.mchId,
            userName: value
          };
          var defaultCfg = {
            isBody: true, //是否是请求体请求
          };
          self.sa.ajax(
            "/v2/admin/setUserName",
            p,
            function () {
              return this.sa.ok("修改成功");
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    modifyRemarks: function (data) {
      let self = this;
      this.sa.prompt(
        "输入备注",
        function (value) {
          var p = {
            mchId: data.mchId,
            remarks: value
          };
          var defaultCfg = {
            isBody: true, //是否是请求体请求
          };
          self.sa.ajax(
            "/v2/admin/setRemarks",
            p,
            function () {
              this.f5();
              return this.sa.ok("修改成功");
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    modifyPassword: function (data) {
      let self = this;
      this.sa.prompt(
        "输入要修改的密码",
        function (value) {
          var p = {
            mchId: data.mchId,
            password: value
          };
          var defaultCfg = {
            isBody: true, //是否是请求体请求
          };
          self.sa.ajax(
            "/v2/admin/resetPassword",
            p,
            function () {
              return this.sa.ok("修改成功");
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    del: function (data) {
      this.sa.confirm(
        "是否删除，此操作不可撤销",
        function () {
          var params = {
            mch_id: data.mchId, //是否是请求体请求
          };
          this.sa.ajax(
            "/v1/admin/delMerchant",
            params,
            function () {
              this.sa.arrayDelete(this.dataList, data);
              this.sa.ok("删除成功");
            }.bind(this)
          );
        }.bind(this)
      );
    },
    //修改账号状态
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改账号状态",
        function () {
          var params = {
            mch_id: data.mchId, //是否是请求体请求
            state: data.state == 1 ? 0 : 1, //是否是请求体请求
          };
          this.sa.ajax(
            "/v1/admin/setDisableMerchant",
            params,
            function () {
              this.f5();
            }.bind(this)
          );
        }.bind(this)
      );
    },
    /** 展示用户列表页面 **/
    showUserInfoPage() {
      // 设置
      if (this.multipleSelection == null) {
        return this.sa.error2("请选择要切换的商户");
      }
      this.userDialogVisible = true;
    },
    /** 关闭用户列表页面 **/
    cancel() {
      this.userDialogVisible = false;
      this.switchMchId == null;
      this.multipleSelection == null;
    },
    checkSelectable(row) {
      return row.roleId == "1";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    switchMch() {
      if (this.switchMchId == null) {
        return this.sa.error2("未选择码队");
      }

      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var params = {
        mch_id: this.createMchs(this.multipleSelection),
        codeMch: this.switchMchId,
        codeMchName: this.codeMchName,
      };
      this.sa.ajax(
        "/v1/mch_relation/switchMchId",
        params,
        function () {
          this.sa.ok("切换成功");
          this.cancel();
          this.f5();
          //刷新页面
        }.bind(this),
        defaultCfg
      );
    },
    getSwitchMchId(val) {
      console.log(val);
      this.switchMchId = val;
    },
    //构建需要修改的参数
    createMchs(val) {
      var list = [];
      for (var i = 0; i < val.length; i++) {
        list[i] = val[i].mchId;
      }
      return list;
    },
    update: function () {
      this.$refs["edit-user-password"].open(0);
    },
    yesterDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = null;
      if (data.roleId == "1") {
        p2 = {
          mchId: data.mchId, //是否是请求体请求
        };
      } else if (data.roleId == "3") {
        p2 = {
          codeMchId: data.mchId, //是否是请求体请求
        };
      }
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountYesterday",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.zrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = null;
      if (data.roleId == "1") {
        p2 = {
          mchId: data.mchId, //是否是请求体请求
        };
      } else if (data.roleId == "3") {
        p2 = {
          codeMchId: data.mchId, //是否是请求体请求
        };
      }
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    remarksFormat(row) {
      if (row.remarks == "" || row.remarks == null) {
        return "无备注";
      } else {
        return row.remarks;
      }
    },
    liuPayTypeTJ: function (data) {
      var tab = null;
      if (data.roleId == "1") {

        tab = { id: 'transaction', name: '流水报表', view: () => import('@/sa-view/statistic/transaction.vue'), params: { mchId: data.mchId } };
        this.sa_admin.showTab(tab);
        this.sa_admin.getView('transaction').update(data.mchId, null);
      } else if (data.roleId == "3") {

        tab = { id: 'transaction', name: '流水报表', view: () => import('@/sa-view/statistic/transaction.vue'), params: { codeMchId: data.mchId } };
        this.sa_admin.showTab(tab);
        this.sa_admin.getView('transaction').update(null, data.mchId);
      }
    },
    newPayTypeTJ: function (data) {
      var tab = null;
      if (data.roleId == "1") {

        tab = { id: 'channel', name: '通道统计', view: () => import('@/sa-view/statistic/channel.vue'), params: { mchId: data.mchId } };
        this.sa_admin.showTab(tab);
        this.sa_admin.getView('channel').update(data.mchId, null);
      } else if (data.roleId == "3") {

        tab = { id: 'channel', name: '通道统计', view: () => import('@/sa-view/statistic/channel.vue'), params: { codeMchId: data.mchId } };
        this.sa_admin.showTab(tab);
        this.sa_admin.getView('channel').update(null, data.mchId);
      }
    },
    payTypeTJ: function (data) {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = null;
      if (data.roleId == "1") {
        p2 = {
          mchId: data.mchId, //是否是请求体请求
        };
      } else if (data.roleId == "3") {
        p2 = {
          codeMchId: data.mchId, //是否是请求体请求
        };
      }
      this.sa.ajax(
        "/v1/dataAnalysis/dataAnalysisPayTypeToDay",
        p2,
        function (res) {
          if (res.data == null) {
            this.sa.alert("暂无数据");
            return;
          }
          let len = res.data.length;
          var style = "<style>table {border-collapse: collapse;}</style>"
          var str = style + "<table border='1' style='text-align: center;'>"
            + "<tr>"
            + "<th>通道名称</th>"
            + "<th>昨日金额</th>"
            + "<th>今日金额</th>"
            + "</tr>";
          for (let i = 0; i < len; i++) {
            console.log(res.data[i]);
            str = str + "<tr>";
            str = str + "<td width='600px'>" + res.data[i].channelAlias + "</td>"
            str = str + "<td width='600px'>" + res.data[i].yesterReallyAmount + "</td>"
              + "<td width='600px'>" + res.data[i].todayReallyAmount + "</td>"
              + "</tr >";
          }
          str = str + "</table>"
          this.sa.alert2(str, { area: ["600px", "400px"] });
        }.bind(this),
        defaultCfg
      );
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style></style>
