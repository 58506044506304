<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog
    v-if="m"
    :title="this.id == 0 ? '数据增加' : '数据修改'"
    :visible.sync="isShow"
    width="400px"
    top="25vh"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <div class="c-panel">
        <!-- 参数栏 -->
        <br />
        <el-form size="mini">
          <el-form size="medium" :inline="true">
            <el-form-item label="密码">
              <el-input v-model="m.wechatMch"></el-input>
            </el-form-item>
            <br />
            <el-form-item label="确认密码">
              <el-input v-model="m.wechatMch"></el-input>
            </el-form-item>
            <br />
          </el-form>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        password: "", // 从菜单配置文件里传递过来的参数
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        console.log("数据添加");
        this.id = 0;
      } else {
        // id 非 0 代表 是要修改 先查询一下，把旧值显示在input中
        console.log("数据修改");
        console.log(data);
        this.m.wechatMch = data.wechatMch;
        this.m.providerMch = data.providerMch;
        this.m.remarks = data.remarks;
        this.m.status = data.status;
        this.m.mchName = data.mchName;
      }
    },
    // 表单验证
    submit_check: function () {
      // var m = this.m;
      // var sa = this.sa;
      // if (sa.isNull(m.name)) {
      //   return sa.error("请输入名称");
      // }
      // if (sa.isNull(m.icon)) {
      //   return sa.error("请输入图标");
      // }
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          "/v1/wechat/mch/save",
          this.m,
          function () {
            self.sa.alert("增加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              this.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/data/update",
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                this.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      this.m.wechatMch = "";
      this.m.providerMch = "";
      this.m.remarks = "";
      this.m.status = 0;
      this.m.mchName = "";
    },
  },
  created: function () {},
};
</script>
